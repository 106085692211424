<template>
    <header class="px-4 py-2 flex justify-between bg-blue-500">
            <h1 class="text-2xl font-serif font-semibold lowercase text-gray-100">simpos</h1>
            <div class="md:hidden">
                <SpecialNavLink :href="route('auth.show.signin')" class="w-full" :active="route().current('auth.show.signin')" title="Login">
                    <span class="text-center w-full">Login</span>
                </SpecialNavLink>
            </div>
            <ul :class="showMenu ? 'flex' : 'hidden md:flex'" class="justify-center gap-y-2 z-50 items-center fixed h-screen md:h-min md:w-2/3 w-screen left-0 top-0 bg-blue-500/90 md:bg-inherit md:relative flex-col md:gap-x-4 md:flex-row md:flex-nowrap">

                <li class="md:hidden">
                    <button @click="showMenu = false" title="Close" class="text-gray-600 hover:text-gray-900 absolute right-4 top-2">
                        <font-awesome-icon class="text-2xl text-gray-100 font-medium" icon="times" />
                    </button>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.home')" class="w-full" :active="route().current('guest.home')" title="Home"> 
                        <span class="text-center w-full">Home</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-max">
                    <SpecialNavLink :href="route('auth.show.signup')" class="w-full" :active="route().current('auth.show.signup')" title="Create Account">
                        <span class="text-center w-full">Register</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('auth.show.signin')" class="w-full" :active="route().current('auth.show.signin')" title="Login">
                        <span class="text-center w-full">Login</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 flex md:hidden lg:flex md:w-max">
                    <SpecialNavLink :href="route('guest.about')" class="w-full" :active="route().current('guest.about')" title="About Us">
                        <span class="text-center w-full">About us</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.contact')" class="w-full" :active="route().current('guest.contact')" title="Contact Us">
                        <span class="text-center w-full">Contact</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 md:w-min">
                    <SpecialNavLink :href="route('guest.pricing')" class="w-full" :active="route().current('guest.pricing')" title="Pricing">
                        <span class="text-center w-full">Pricing</span>
                    </SpecialNavLink>
                </li>
                <li class="w-4/5 flex md:hidden lg:flex md:w-max">
                    <SpecialNavLink :href="route('guest.affiliate')" class="w-full" :active="route().current('guest.affiliate')" title="Become an affiliate">
                        <span class="text-center w-full">Become an affiliate</span>
                    </SpecialNavLink>
                </li>
            </ul>
            <div class="lg:hidden">
                <button @click="showMenu = ! showMenu" title="Menu" class="flex items-center p-2 rounded-md mr-1 text-gray-800 dark:text-gray-200 hover:opacity-85 focus:outline-none transition duration-150 ease-in-out">
                    <font-awesome-icon icon="bars" class="text-lg text-gray-100" />
                </button>
            </div>
            <ul v-if="showMenu" class="absolute shadow-md p-3 rounded border border-gray-200 bg-gray-50 hidden md:flex md:flex-col lg:hidden top-14 right-2">
                <li class="">
                    <SpecialNavLink :href="route('guest.about')" class="w-full hover:underline" :active="route().current('guest.about')" title="About Us">
                        <span class="text-gray-800 w-full">About us</span>
                    </SpecialNavLink>
                </li>
                <li class="">
                    <SpecialNavLink :href="route('guest.affiliate')" class="w-full hover:underline" :active="route().current('guest.affiliate')" title="Become an affiliate">
                        <span class="text-gray-800 w-full">Become an affiliate</span>
                    </SpecialNavLink>
                </li>
            </ul>
        </header>
</template>
<script setup>
    import SpecialNavLink from '@/Components/SpecialNavLink.vue';
    import { ref } from 'vue';

    const showMenu = ref(false);
</script>