import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faSpinner, faPhone, faPrint, faUser, faTimes, faMoon, faSignOut, faCartShopping, faHouseChimney, faBell, faUsers, faSun, faTrash, faPencil, faEye, faEnvelope, faCar, faSackDollar, faCalendarDays, faCarOn, faStarOfDavid, faGasPump, faGears, faHeadset, faChevronLeft, faChevronRight, faMoneyCheckDollar, faStore, faUserFriends, faHandshake, faFileInvoice, faCreditCard, faListCheck, faChevronDown, faPlus, faArrows, faRightLeft, faReceipt, faArrowRight, faArrowUp, faEnvelopeOpen, faUserCheck, faShop, faBoxes, faFileInvoiceDollar, faDollarSign, faWallet, faUserTie, faCoins, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { faFacebook, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
library.add(faBars, faCoins, faShop, faArrowUpRightFromSquare, faBoxes, faUserTie, faSackDollar, faWallet, faReceipt, faDollarSign, faFileInvoiceDollar, faEnvelopeOpen, faUserCheck, faLinkedin, faWhatsapp, faArrowUp, faFacebook, faArrowRight, faPlus, faArrows, faRightLeft, faPrint, faListCheck, faChevronDown, faPhone, faSpinner, faUser, faCartShopping, faMoon, faSignOut, faHouseChimney, faBell, faUsers, faSun, faTrash, faPencil, faEye, faEnvelope, faCar, faSackDollar, faCalendarDays, faCarOn, faStarOfDavid, faGasPump, faHeadset, faGears, faChevronLeft, faChevronRight, faMoneyCheckDollar, faStore, faUserFriends, faHandshake, faFileInvoice, faCreditCard, faTimes);

const appName = import.meta.env.VITE_APP_NAME;
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .component('VueDatePicker', VueDatePicker)
            .use(VueSweetalert2, {confirmButtonColor: '#2563eb'})
            .component("font-awesome-icon", FontAwesomeIcon)
            .mount(el);
    },
    progress: {
        color: '#3730a3',
    },
});
